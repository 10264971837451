ul {
  list-style: none;
}

.contact {
  width: 300px;
  margin-left: -20px;
}

button {
  font-family: Arial;
  color: #000000;
  padding: 0;
  text-decoration: none;
  border: none;
  background-color: #fff;
}

button:hover {
  text-decoration: none;
  color: blue;
}

@media screen and (max-width: 440px) {
  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: red;
  }
  a:hover .navbar-nav:hover .nav-link:hover,
  link:hover {
    background: purple;
    color: yellow;
  }
}
