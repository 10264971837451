/* https://css-tricks.com/perfect-full-page-background-image/ */
/* https://stackoverflow.com/questions/36150153/how-can-i-apply-page-specific-full-screen-background-image-using-react */
#bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner {
  background-color: #626262;
  background-color: rgba(98, 98, 98, 0.3);

  border-radius: 26px;
  width: 75%;
  margin: auto;
  margin-top: 300px;
}

.banner-text {
  text-align: center;
  padding: 10px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 10px;
}

.shadow {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.stroke {
  -webkit-text-stroke: 1px black;
  color: #ffffff;
}
